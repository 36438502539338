/**
 * Short Coming Soon Component
 * Author : vijay verma
 * Created : 8-Jan-2020
 */
 import React from "react"
 import { useStaticQuery, graphql } from "gatsby"
 import Image from "gatsby-image"


 const Soon = () => {
   return (
     <>
     <section className="sushi-header sushi-soon">
         <div className="container">
           <div className="sushi-hero">
             <img src="/sushi-logo.png"/>
             <p>Zomato's very own design system provides components that reflect our brand promise, and create consistent and intuitive user experiences across our platform.</p>
             <a href="https://medium.com/zomato-technology/zomatos-new-sushi-design-system-d7f4f98664c5" className="lnk-soon">Read about it</a>
           </div>
         </div>
     </section>
     </>
   )
 }

 export default Soon
